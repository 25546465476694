<template>
    <div>
        <CRow>
            <CCol sm="6">
                <CCard>
                    <CCardHeader>
                        <strong> Wlan Configuration </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardBody>
                        <strong> Where to host this Wlan? </strong> <small></small>
                        <div class="card-header-actions"></div>
                        <CRow sm="6">
                            <CCol sm="2">
                                <CSelect v-if="states"
                                         label="State"
                                         :options="states"
                                         :value.sync="state_id"
                                         @update:value="getCities()"
                                />

                            </CCol>

                            <CCol sm="2">
                                <CSelect v-if="cities"
                                         label="Cities"
                                         :options="cities"
                                         :value.sync="cit_id"
                                         @update:value="getProperties()"
                                />

                            </CCol>

                            <CCol sm="4">
                                <CSelect v-if="properties"
                                         label="Properties"
                                         :options="properties"
                                         :value.sync="pro_id"
                                         @update:value="getZones()"
                                />

                            </CCol>

                            <CCol sm="4">
                                <CSelect v-if="zones"
                                         label="Zones"
                                         :options="zones"
                                         :value.sync="zone_id"
                                         @update:value="getZones()"
                                />

                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                        label="Wlan SSID"
                                        v-model="wlan_ssid"
                                        :value.sync="wlan_data.wlan_ssid"
                                        placeholder="Enter User's network names"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                        label="Wlan Passphrase"
                                        v-model="wlan_passphrase"
                                        :value.sync="wlan_data.wlan_passphrase"
                                        placeholder="Enter User's network passphrase"
                                />
                            </CCol>
                        </CRow>

                        <CRow sm="6">
                            <CCol sm="2">
                                <CInput
                                        label="VLAN"
                                        placeholder=""
                                        id="vlan-id"
                                        v-model="user_data.rvlan_vlanid"
                                        :disabled="vlanUpd"
                                        :value.sync="user_data.rvlan_vlanid"
                                />
                            </CCol>
                            <CCol sm="4">

                                <CInputCheckbox
                                        style="margin-top: 25px"
                                        value="vlanUpd"
                                        label="Custom VLAN"
                                        :inline="true"
                                        @change="inputChbCallback()"
                                />
                                <CInputCheckbox
                                        style="margin-top: 5px"
                                        value="assignUser"
                                        label="Assigned to user"
                                        :inline="true"
                                        @change="inputUserCallback()"
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>

                        <div v-if="processing" class="lds-ripple">
                            <div></div>
                            <div></div>
                        </div>
                        <div v-else>
                            <CButton v-if="!create_button" disabled type="submit" size="sm" color="primary">
                                <CIcon name="cil-check-circle"/>
                                Create
                            </CButton>

                            <CButton v-else type="submit" @click="createWlan()" size="sm" color="primary">
                                <CIcon name="cil-check-circle"/>
                                Create
                            </CButton>
                            <CButton style="margin-left: 10px" type="reset" size="sm" color="danger">
                                <CIcon name="cil-ban"/>
                                Reset
                            </CButton>
                        </div>

                    </CCardFooter>
                </CCard>
                <CCard v-if="zone_id">
                    <CCardHeader>
                        <strong> AP Configuration </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardHeader>
                        <CInput v-model="ap_mac" placeholder="AP MAC">
                            <template #prepend>
                                <CButton @click="apMacLookup()" size="sm" color="primary">

                                    <CIcon name="cil-magnifying-glass"/>
                                    Search
                                </CButton>
                            </template>
                        </CInput>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="6">
                                <CInput
                                        label="AP Name"
                                        :value="ap_data.deviceName"
                                        disabled
                                />
                            </CCol>
                            <CCol sm="6">
                                <CInput
                                        label="AP Status"
                                        :value="ap_data.status"
                                        disabled
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                        label="Zone Name"
                                        :value="ap_data.zoneName"
                                        disabled
                                />
                            </CCol>
                            <CCol sm="12">
                                <CInput
                                        label="WlanGroup24Id"
                                        :value="ap_data.wlanGroup24Id"
                                        disabled
                                />
                            </CCol>
                            <CCol sm="12">
                                <CInput
                                        label="WlanGroup50Id"
                                        :value="ap_data.wlanGroup50Id"
                                        disabled
                                />
                            </CCol>
                        </CRow>

                    </CCardBody>
                </CCard>
            </CCol>


            <CCol sm="6">
                <CCard v-if="!assignUser">
                    <CCardHeader>
                        <strong> User Configuration </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardHeader>
                        <CInput @input="userLookup()" v-model="search_email" placeholder="user's email">
                            <template #prepend>
                                <CButton size="sm" color="primary">
                                    <CIcon name="cil-magnifying-glass"/>
                                    Search
                                </CButton>
                            </template>
                        </CInput>
                    </CCardHeader>
                    <CCardBody>
                        <CInput
                                label="User Email"
                                placeholder=""
                                disabled
                                :value="user_data.usr_email"
                        />
                        <CInput
                                label="User FirstName"
                                placeholder=""
                                disabled
                                :value="user_data.usr_firstname"
                        />
                        <CInput
                                label="User LastName"
                                placeholder=""
                                disabled
                                :value="user_data.usr_lastname"
                        />

                        <CInput
                                label="Building"
                                placeholder=""
                                disabled
                                :value="user_data.bul_name"
                        />

                        <CInput
                                label="Unit"
                                placeholder=""
                                disabled
                                :value="user_data.uni_name"
                        />

                        <CInput
                                label="Property"
                                placeholder=""
                                disabled
                                :value="user_data.pro_shortname"
                        />
                    </CCardBody>
                </CCard>
                <CCard v-if="!assignUser">
                    <CCardHeader>
                        <strong> User's Wlan </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardBody>
                        <CInput
                                label="Wlan Name"
                                placeholder=""
                                disabled
                                :value="wlan_data.wlan_ssid"
                        />
                        <CInput
                                label="Wlan Passphrase"
                                placeholder=""
                                disabled
                                :value="wlan_data.wlan_passphrase"
                        />
                    </CCardBody>
                </CCard>
            </CCol>

        </CRow>

    </div>
</template>
<script>
    import axios from "axios";

    export default {
        name: 'CreateWlan',
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.usersOpened = from.fullPath.includes('wlans')
            })
        },
        data() {
            return {

                processing: false,
                vlanUpd: true,
                assignUser: true,
                wlan_ssid: "",
                wlan_passphrase: "",
                wlan_vlan: "",
                create_button: false,

                ap_mac: "",
                option: null,

                user_data: {
                    usr_email: '',
                    usr_firstname: '',
                    usr_lastname: '',
                    bul_name: '',
                    uni_name: '',
                    pro_shortname: '',
                    rvlan_vlanid: ''
                },
                wlan_data: {
                    wlan_ssid: '',
                    wlan_passphrase: ''
                },

                states: null,
                state_id: null,

                cities: null,
                cit_id: null,

                properties: null,
                pro_id: null,

                zones: null,
                zone_id: null,
                search_email: '',
                ap_data: {
                    deviceName: '',
                    status: '',
                    zoneName: '',
                    wlanGroupId24: '',
                    wlanGroupId50: ''
                }
            }
        },
        methods: {
            getStates() {

                axios.get(this.$baseURL + '/treeview/states')
                    .then((res) => {
                        this.states = [];
                        this.states.push({label: "Select State", value: null})
                        res.data.forEach(e => {
                            this.states.push({label: e.state_name, value: e.state_id});
                        })
                    })

            },
            getCities() {
                if (!this.state_id) {
                    return
                }

                localStorage.setItem('state_id', this.state_id);

                axios.get(this.$baseURL + '/treeview/city?state_id=' + this.state_id)
                    .then((res) => {
                        this.cities = [];
                        this.cities.push({label: "Select City", value: null})
                        res.data.forEach(e => {
                            this.cities.push({label: e.cit_name, value: e.cit_id});
                        })
                    })

            },

            getProperties() {
                if (!this.cit_id) {
                    return
                }
                localStorage.setItem('cit_id', this.cit_id);
                axios.get(this.$baseURL + '/treeview/properties?cit_id=' + this.cit_id)
                    .then((res) => {
                        this.properties = [];
                        this.properties.push({label: "Select Property", value: null})
                        res.data.forEach(e => {
                            this.properties.push({label: e.pro_shortname, value: e.pro_id});
                        })
                    })

                this.getUsers('cit_id', this.cit_id);
            },

            getZones() {
                if (!this.pro_id) {
                    return
                }
                localStorage.setItem('pro_id', this.pro_id);
                axios.get(this.$baseURL + '/treeview/zones?pro_id=' + this.pro_id)
                    .then((res) => {
                        this.zones = [];
                        this.zones.push({label: "Select Zone", value: null})
                        res.data.forEach(e => {
                            this.zones.push({label: e.zone_name, value: e.zone_id});
                        })
                    })
                this.getUsers('pro_id', this.pro_id);

            },
            inputChbCallback() {

                this.vlanUpd = !this.vlanUpd;
            },
            inputUserCallback() {

                this.assignUser = !this.assignUser;
            },
            apMacLookup() {

                if (this.ap_mac.length < 17) {
                    this.$toast.error("AP MAC len. is invalid");
                    return
                }

                axios.get(this.$baseURL + '/ruckus/ap/search?ap_mac=' + this.ap_mac + "&zone_id=" + this.zone_id)
                    .then(
                        res => {

                            this.ap_data = res.data;

                            if (this.ap_data.status == 'Offline') {
                                this.$toast.error("You cannot host Wlan here because AP is offline.")
                                this.create_button = false;
                            } else {
                                this.$toast.success("Successfully fetched AP.")
                                this.create_button = true;
                            }
                        }
                    ).catch((error) => {

                    this.$toast.error(error.response.data.detail);

                })
            },

            userLookup() {
                this.user_data = {
                    usr_email: '',
                    usr_firstname: '',
                    usr_lastname: '',
                    bul_name: '',
                    uni_name: '',
                    pro_shortname: '',
                    rvlan_vlanid: ''
                }
                axios.get(this.$baseURL + '/users/search?email=' + this.search_email)
                    .then(
                        res => {
                            this.user_data = res.data;
                            this.wlanLookup()
                        }
                    ).catch((error) => {
                    error;
                })
            },

            createWlan() {
                this.processing = true;
                this.wlan_data.wlan_passphrase = this.wlan_passphrase;
                this.wlan_data.wlan_ssid = this.wlan_ssid;
                this.wlan_data.rvlan_vlanid = document.getElementById('vlan-id')._value

                axios.post(this.$baseURL + '/ruckus/wl/create', {
                    ap_data: this.ap_data,
                    wlan_data: this.wlan_data,
                    usr_data: this.user_data,
                }).then((res) => {
                    console.log(res);
                    this.$toast.success("Successfully created network.");
                    this.processing = false;
                }).catch((error) => {
                    this.$toast.error("Failed to create network." + error.response.data.detail);
                    this.processing = false;
                })
            },
            wlanLookup() {

                this.wlan_data = {}
                axios.get(this.$baseURL + '/ruckus/wl/by_usr_id?usr_id=' + this.user_data.usr_id)
                    .then(
                        res => {

                            this.wlan_data = res.data;
                        }
                    ).catch((error) => {
                    error;

                })

            },
            goBack() {
                this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/wlans'})
            },
        },
        mounted() {
            this.validateSession()
            this.getStates()
        }
    }
</script>


<style scoped>
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #4673e7;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #4673e7;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }


</style>